/** @format */

import { CALL_API } from 'redux-api-middleware';

import { objectToQueryString } from '../lib/urls';
import { Endpoints as url } from '../lib/endpoints';
import { getCurrentUserToken } from '../lib/auth';

// ACTION TYPES
export const FETCH_ENTITY_MANAGER_IMPORT_LIST_REQUEST =
  'entity-manager-import-list/FETCH_REQUEST';
export const FETCH_ENTITY_MANAGER_IMPORT_LIST_DEVICE_SUCCESS =
  'entity-manager-import-list/FETCH_SUCCESS';
export const FETCH_ENTITY_MANAGER_IMPORT_LIST_DEVICE_FAILURE =
  'entity-manager-import-list/FETCH_FAILURE';
export const FETCH_ENTITY_MANAGER_IMPORT_DETAIL_REQUEST =
  'entity-manager-import-detail/FETCH_REQUEST';
export const FETCH_ENTITY_MANAGER_IMPORT_DETAIL_DEVICE_SUCCESS =
  'entity-manager-import-detail/FETCH_SUCCESS';
export const FETCH_ENTITY_MANAGER_IMPORT_DETAIL_DEVICE_FAILURE =
  'entity-manager-import-detail/FETCH_FAILURE';
export const FETCH_ENTITY_MANAGER_EXPORT_LIST_REQUEST =
  'entity-manager-export-list/FETCH_REQUEST';
export const FETCH_ENTITY_MANAGER_EXPORT_LIST_DEVICE_SUCCESS =
  'entity-manager-export-list/FETCH_SUCCESS';
export const FETCH_ENTITY_MANAGER_EXPORT_LIST_DEVICE_FAILURE =
  'entity-manager-export-list/FETCH_FAILURE';
export const FETCH_ENTITY_MANAGER_EXPORT_DETAIL_REQUEST =
  'entity-manager-export-detail/FETCH_REQUEST';
export const FETCH_ENTITY_MANAGER_EXPORT_DETAIL_DEVICE_SUCCESS =
  'entity-manager-export-detail/FETCH_SUCCESS';
export const FETCH_ENTITY_MANAGER_EXPORT_DETAIL_DEVICE_FAILURE =
  'entity-manager-export-detail/FETCH_FAILURE';
export const CREATE_ENTITY_MANAGER_EXPORT_REQUEST =
  'entity-manager-export-create/CREATE_REQUEST';
export const CREATE_ENTITY_MANAGER_EXPORT_SUCCESS =
  'entity-manager-export-create/CREATE_SUCCESS';
export const CREATE_ENTITY_MANAGER_EXPORT_FAILURE =
  'entity-manager-export-create/CREATE_FAILURE';
export const DESTROY_ENTITY_MANAGER_EXPORT_SUCCESS =
  'entity-manager-export-destroy/DESTROY_SUCCESS';
export const DESTROY_ENTITY_MANAGER_EXPORT_DETAIL_SUCCESS =
  'entity-manager-export-detail-destroy/DESTROY_SUCCESS';

// ACTION CREATORS
export const fetchEntityManagerImportList = (entity, query = {}) => ({
  [CALL_API]: {
    endpoint: `${url.EntityManagerImportList(entity)}${
      Object.keys(query).length > 0 ? `?${objectToQueryString(query)}` : ''
    }`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      FETCH_ENTITY_MANAGER_IMPORT_LIST_REQUEST,
      {
        type: FETCH_ENTITY_MANAGER_IMPORT_LIST_DEVICE_SUCCESS,
        payload: (action, state, res) => res.json().then(json => json),
      },
      {
        type: FETCH_ENTITY_MANAGER_IMPORT_LIST_DEVICE_FAILURE,
        payload: (action, state, res) => {
          // eslint-disable-next-line no-console
          console.log(action, state, res);
          // history.push(`${PAGES_PATH}/devices`);
          // return res.json().then(err => toastError(err));
        },
      },
    ],
  },
});

export const fetchEntityManagerImportDetail = (entity, id, query = {}) => ({
  [CALL_API]: {
    endpoint: `${url.EntityManagerImportDetail(entity, id)}${
      Object.keys(query).length > 0 ? `?${objectToQueryString(query)}` : ''
    }`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      FETCH_ENTITY_MANAGER_IMPORT_DETAIL_REQUEST,
      {
        type: FETCH_ENTITY_MANAGER_IMPORT_DETAIL_DEVICE_SUCCESS,
        payload: (action, state, res) => res.json().then(json => json),
      },
      {
        type: FETCH_ENTITY_MANAGER_IMPORT_DETAIL_DEVICE_FAILURE,
        payload: (action, state, res) => {
          // eslint-disable-next-line no-console
          console.log(action, state, res);
          // history.push(`${PAGES_PATH}/devices`);
          // return res.json().then(err => toastError(err));
        },
      },
    ],
  },
});

// ACTION EXPORTS
export const fetchEntityManagerExportList = (entity, query = {}) => ({
  [CALL_API]: {
    endpoint: `${url.EntityManagerExportList(entity)}${
      Object.keys(query).length > 0 ? `?${objectToQueryString(query)}` : ''
    }`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      FETCH_ENTITY_MANAGER_EXPORT_LIST_REQUEST,
      {
        type: FETCH_ENTITY_MANAGER_EXPORT_LIST_DEVICE_SUCCESS,
        payload: (action, state, res) => res.json().then(json => json),
      },
      {
        type: FETCH_ENTITY_MANAGER_EXPORT_LIST_DEVICE_FAILURE,
        payload: (action, state, res) => {
          // eslint-disable-next-line no-console
          console.log(action, state, res);
          // history.push(`${PAGES_PATH}/devices`);
          // return res.json().then(err => toastError(err));
        },
      },
    ],
  },
});

export const fetchEntityManagerExportDetail = (entity, id, query = {}) => ({
  [CALL_API]: {
    endpoint: `${url.EntityManagerExportDetail(entity, id)}${
      Object.keys(query).length > 0 ? `?${objectToQueryString(query)}` : ''
    }`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    types: [
      FETCH_ENTITY_MANAGER_EXPORT_DETAIL_REQUEST,
      {
        type: FETCH_ENTITY_MANAGER_EXPORT_DETAIL_DEVICE_SUCCESS,
        payload: (action, state, res) => res.json().then(json => json),
      },
      {
        type: FETCH_ENTITY_MANAGER_EXPORT_DETAIL_DEVICE_FAILURE,
        payload: (action, state, res) => {
          // eslint-disable-next-line no-console
          console.log(action, state, res);
          // history.push(`${PAGES_PATH}/devices`);
          // return res.json().then(err => toastError(err));
        },
      },
    ],
  },
});

export const createExportEntities = (entity, filters) => ({
  [CALL_API]: {
    endpoint: url.EntitiesExport(entity),
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: getCurrentUserToken(),
    },
    body: JSON.stringify(filters),
    types: [
      CREATE_ENTITY_MANAGER_EXPORT_REQUEST,
      {
        type: CREATE_ENTITY_MANAGER_EXPORT_SUCCESS,
        payload: (action, state, res) =>
          res.json().then(json => {
            // history.push({ pathname: `${PAGES_PATH}/device/${json.id}` });
            return json;
          }),
      },
      {
        type: CREATE_ENTITY_MANAGER_EXPORT_FAILURE,
        payload: (action, state, res, err) => err,
        // res.json().then(errtoast => toastError(errtoast)),
      },
    ],
  },
});

export const destroyExportEntities = () => ({
  type: DESTROY_ENTITY_MANAGER_EXPORT_SUCCESS,
});

export const destroyExportEntityDetail = () => ({
  type: DESTROY_ENTITY_MANAGER_EXPORT_DETAIL_SUCCESS,
});
