/** @format */

import React, { useState, useEffect } from 'react';
import { Dropdown, Form } from 'semantic-ui-react';
import axios from 'axios';
import PropTypes from 'prop-types';

import { Endpoints as url } from '../../lib/endpoints';
import { getCurrentUserToken } from '../../lib/auth';

const UserDropdown = ({ onChange = (e, { name, value }) => {}, ...props }) => {
  const [value, setValue] = useState(null);
  const [users, setUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  // const s = useSelector(state => state);

  useEffect(() => {
    fetchUsers();
  }, [searchQuery, page]);

  useEffect(() => {
    onChange({}, { name: 'uid', value });
  }, [value]);

  const fetchUsers = async () => {
    if (loading || !hasMore) return;

    setLoading(true);
    try {
      const response = await axios.get(url.Users(), {
        params: {
          username: searchQuery,
          offset: page,
          limit: 20,
          not_roles: ['system', 'admin'],
        },
        headers: {
          Authorization: `${getCurrentUserToken()}`,
        },
      });

      setUsers([...response.data.rows]);
      setHasMore(response.data.rows.length > 0);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (e, { searchQuery: _searchQuery }) => {
    setSearchQuery(_searchQuery);
    setUsers([]);
    setPage(0);
    setHasMore(true);
  };

  const handleOnChange = (e, { value: _value }) => {
    setValue(_value);
    if (_value === '') {
      setSearchQuery();
    }
  };

  const handleScroll = e => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    if (bottom && hasMore) {
      setPage(prevPage => prevPage + 1);
    }
  };

  return (
    <>
      <Form.Field
        search
        selection
        options={users.map(user => ({
          key: user.id,
          text: user.username,
          value: user.id,
        }))}
        onSearchChange={handleSearchChange}
        onScroll={handleScroll}
        loading={loading}
        clearable
        onChange={handleOnChange}
        // value={value}
        control={Dropdown}
        {...props}
      />
    </>
  );
};

UserDropdown.propTypes = {
  onChange: PropTypes.func,
};

export { UserDropdown };
